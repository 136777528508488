import logo from "../image/logo_dark.svg";
import logo_short from "../image/logo_short.svg";
import want_to_talk from "../image/want_to_talk.png";

export default {
  buttonViewGoalBackground: "#E1F1F4",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimary: "#233F6F",
  colorPrimaryButtonText: "#FFFFFF",
  comparisonProductsColors: ["#7908A3", "#0076FF", "#001A45"],
  goalsOnboardingBackground: "#EDF0F2",
  goalsOnboardingStepDividerLineColor: "#233F6F",
  goalsOnboardingButtonContinueBackground: "#233F6F",
  goalsOnboardingButtonContinueColor: "#ffffff",
  goalsOnboardingButtonContinueBorderRadius: 30,
  goalsOnboardingSectionBasicInfoListMarkerBackground: "#516474",
  goalsOnboardingSectionRiskToleranceButtonIconColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonIconBackground: "#596e93",
  goalsOnboardingSectionRiskToleranceButtonColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonConservativeBackground: "#233F6F",
  goalsOnboardingSectionRiskToleranceButtonModerateBackground: "#233F6F",
  goalsOnboardingSectionRiskToleranceButtonAggressiveBackground: "#233F6F",
  goalsOnboardingFormRegistrationButtonSubmitBackground: "#233F6F",
  goalsOnboardingFormRegistrationButtonSubmitBorderRadius: 30,
  goals_page_dash_show_section_social: true,
  iconGoalTypeBackground: "#8D28CE",
  iconGoalTypeColor: "#FFFFFF",
  incomeChartWithTypesContributionsColor: "#4997D1",
  incomeChartWithTypesNavbarButtonBackground: "#FFFFFF",
  incomeChartWithTypesNavbarButtonColor: "#2A2828",
  incomeChartWithTypesNavbarContainerBackground: "#D9F3F7",
  logoSrc: logo,
  overlay_background: "rgba(39, 90, 144, 0.8)",
  pageDashAllEpisodesButtonBackground: "#233f6f",
  pageDashCreateNewGoalsButtonBackground: "#233f6f",
  pageDashCreateNewGoalsButtonColor: "#FFFFFF",
  page_dash_section_want_talk_button_background: "#233f6f",
  page_dash_section_want_talk_button_color: "#FFFFFF",
  page_dash_section_want_talk_button_copy: "Ask a Pro",
  page_dash_section_want_talk_copy: `You can click the “Ask a Pro” button any time to get in touch with a financial planner.`,
  pageDashWantToTalkImageLink: want_to_talk,
  pageDashWantToTalkLimitedContent: "",
  pageDashWantToTalkTitleContent: "Want to talk to a<br/>Newland Planner?",
  pageGoalUpdateProgressButtonBackground: "#233f6f",
  pageGoalUpdateProgressButtonColor: "#FFFFFF",
  pageLoginButtonSubmitColor: "#233F6F",
  pageLoginLogo: logo,
  pennySendMessageButtonBackground: "#233f6f",
  sidebarAvatarBackground: "#FFFFFF",
  sidebarAvatarColor: "#1056AA",
  sidebarBackground: "#0D1238",
  sidebarIconColor: "#9EA0AF",
  sidebarIconColorActive: "#FFFFFF",
  sidebarLogo: logo_short,
  spin_color: "#fff",
  tenantColorPrimary: "#233F6F",
};
