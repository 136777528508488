import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Flex, Image, theme } from "antd";
import ChartComparisonGrowth from "../../../../../molecule/ChartComparisonGrowth";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { getLiveAssessment } from "../../../../../utils/request/regularApp";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import checkmark_checked from "./image/checkmark_checked.svg";
import checkmark_unchecked from "./image/checkmark_unchecked.svg";
import { ReactComponent as CheckmarkGrowth } from "./image/checkmark_growth.svg";

const ReportPageProposalGrowth = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessment({
      organization: proposalData.organization,
      objective: {
        ...proposalData,
        advisorFee: getPercentValue(proposalData.advisorFee),
        birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
      },
      productId: proposalData.productId,
    }).then(liveAssessment => {
      setProductsLiveAssessment([liveAssessment]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 1000);
    });
  }, [proposalData]);

  const Checkmarks = () => (
    <Flex gap={14} vertical>
      {["Aggressive", "Moderate", "Conservative"].map(label => (
        <Flex align={"center"} gap={10} key={label}>
          <Flex justify={"end"} style={{ width: 41 }}>
            <Image
              preview={false}
              src={
                proposalData?.riskTolerance?.toLowerCase() ===
                label.toLowerCase()
                  ? checkmark_checked
                  : checkmark_unchecked
              }
            />
          </Flex>
          <span
            style={{
              opacity:
                proposalData?.riskTolerance?.toLowerCase() ===
                label.toLowerCase()
                  ? 1
                  : 0.4,
            }}
          >
            {label}
          </span>
        </Flex>
      ))}
    </Flex>
  );

  return (
    <StylesContainer id="reportPageProposalGrowth">
      <Flex className="page-container" vertical>
        <ReportHeader personaName={personaName} />

        <main>
          <h3>What we talked about</h3>
          <p style={{ width: 783 }}>
            Setting clear financial goals acts as a roadmap for your financial
            journey, ensuring that every decision you make is purposeful and
            directed toward your desired outcomes. By aligning investment
            products with your personal aspirations, it becomes possible to
            regularly assess whether the chosen strategy is effectively guiding
            you toward success and remains advantageous for achieving your
            financial objectives.
          </p>

          <Flex justify="space-between" style={{ marginTop: 100 }}>
            <Flex flex="0 658px" gap={24} vertical>
              <Flex justify="space-between">
                <h3>{proposalData?.productName}</h3>
                <CheckmarkGrowth />
              </Flex>
              {productsLiveAssessment && (
                <ChartComparisonGrowth
                  height={300}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                  proposalData={proposalData}
                />
              )}
            </Flex>

            <Flex gap={24} vertical>
              <h3>Portfolio Mix</h3>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    height: 300,
                    spacingTop: 0,
                    type: "pie",
                    width: 245,
                  },
                  tooltip: {
                    outside: true,
                    style: {
                      zIndex: 9999,
                    },
                  },
                  title: {
                    text: null,
                  },
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    pie: {
                      size: 158,
                      innerSize: "60%",
                      dataLabels: {
                        enabled: false,
                      },
                      showInLegend: true,
                      center: ["50%", "50%"],
                      borderColor: "#ffffff",
                      borderWidth: 2,
                    },
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    labelFormatter: function () {
                      return `<div style="width:245px; white-space: normal;">${
                        this.y + "% " + this.name
                      }</div>`;
                    },
                    itemStyle: {
                      fontSize: 14,
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        enabled: false,
                      },
                    },
                  },
                  colors: token.chartColors,
                  series: [
                    {
                      colorByPoint: true,
                      data: productsList
                        .find(
                          product => product.name === proposalData?.productName
                        )
                        ?.assets?.map(it => ({
                          name: it.assetName,
                          y: it.investmentPercent,
                        })),
                      name: "Selected",
                      size: "100%",
                    },
                  ],
                }}
              />
            </Flex>
          </Flex>

          <Flex gap={10} style={{ marginTop: 78 }} vertical>
            <h3>Your Selections</h3>

            <Flex className="selections-container" gap={130}>
              <div className="value-container">
                <span className="label">Investment Objective</span>
                <p className="value" style={{ textTransform: "capitalize" }}>
                  {proposalData?.investmentObjective}
                </p>
              </div>

              <div className="value-container">
                <span className="label">Initial Investment</span>
                <p className="value">
                  {getCurrencyValue(proposalData?.investmentAmount)}
                </p>
              </div>

              <div className="value-container">
                <span className="label">Risk Tolerance</span>
                <p className="value" style={{ textTransform: "capitalize" }}>
                  {proposalData?.riskTolerance}
                </p>
              </div>

              <div className="value-container">
                <span className="label">Length of Investment</span>
                <p className="value">
                  {proposalData?.investmentDuration} year(s)
                </p>
              </div>
            </Flex>
          </Flex>
        </main>

        <footer>
          <Flex flex="0 672px" gap={9} vertical>
            <div>
              <h3 style={{ fontWeight: 400 }}>About your risk tolerance: </h3>
              <h3 style={{ textTransform: "capitalize" }}>
                {proposalData?.riskTolerance}
              </h3>
            </div>
            <p>
              Investors willing to balance capital appreciation and capital
              preservation and who are willing to accept some volatility and
              potential loss.
            </p>
          </Flex>

          <Checkmarks />
        </footer>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalGrowth;
