import { useContext } from "react";
import State from "../../../../context";
import { ConfigProvider, Flex, Tabs, theme } from "antd";
import RecentLeadCard from "./components/RecentLeadCard";
import RecentProposalCard from "./components/RecentProposalCard";

import StylesContainer from "./styles";

import {
  isManagerAccessNextOnly,
  isOrganizationOneascent,
} from "../../../../utils/helper/specialized";

const RecentUsersSection = ({
  hideClients,
  recentProposalsList,
  showLeads,
}) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const isOneascent = isOrganizationOneascent(
    state.orgName ?? state.organization?.name
  );

  const getDefaultActiveTab = () => {
    if (hideClients) {
      return "leads";
    } else {
      return "clients";
    }
  };

  const getClientsList = () =>
    recentProposalsList.filter(
      it => it.userType !== "Affiliated" && it.userType !== "Lead"
    );

  const getLeadsList = () => {
    if (!state.managedUsers) return [];

    if (isManagerAccessNextOnly(state.access)) {
      // assigned by admin lead
      return state.managedUsers
        .filter(
          it =>
            it.userType === "Assigned" && !it.valueMap?.leadInitial?.isConverted
        )
        .sort((a, b) => b.updateDateTime.$date - a.updateDateTime.$date)
        .reverse();
    }

    if (state.access?.level && state.access?.next) {
      // saved proposal or assigned by admin lead
      return state.managedUsers
        .filter(it => {
          if (it.userType === "Assigned") {
            // remove converted lead
            return !it.valueMap?.leadInitial?.isConverted;
          } else {
            // only saved proposal
            return it.valueMap?.productMap;
          }
        })
        .sort((a, b) => b.updateDateTime.$date - a.updateDateTime.$date)
        .reverse();
    }

    return recentProposalsList
      ?.filter(
        it =>
          it.userType === "Affiliated" ||
          (it.userType === "Assigned" &&
            !it.valueMap?.leadInitial?.isConverted) ||
          it.userType === "Lead"
      )
      .sort((a, b) => b.updateDateTime.$date - a.updateDateTime.$date)
      .reverse();
  };

  const items = [
    {
      key: "clients",
      label: isOneascent ? "Recent Proposals" : "Recent Clients",
      children: (
        <Flex gap={15} vertical>
          {getClientsList()
            ?.slice(0, 3)
            ?.map((it, index) => (
              <RecentProposalCard
                key={index}
                organization={state.organization?.name ?? state.orgName}
                userData={it}
              />
            ))}
        </Flex>
      ),
      hidden: hideClients,
    },
    {
      key: "leads",
      label: "Recent Leads",
      children: (
        <Flex gap={15} vertical>
          {getLeadsList()
            ?.slice(0, 3)
            ?.map((it, index) => (
              <RecentLeadCard key={index} leadData={it} />
            ))}
        </Flex>
      ),
      hidden: !showLeads,
    },
  ].filter(it => !it.hidden);

  return (
    <StylesContainer gap={18} vertical>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: token.advisorDashboardPageActiveTabColor,
              lineWidthBold: 5,
            },
          },
        }}
      >
        <Tabs defaultActiveKey={getDefaultActiveTab()} items={items}></Tabs>
      </ConfigProvider>
    </StylesContainer>
  );
};

export default RecentUsersSection;
