import { useContext } from "react";
import State from "../../../../context";
import { Button, Row, Space, theme } from "antd";
import AnalyticValue from "../../../../atom/AnalyticValue";
import ButtonDemoMode from "../../../../atom/ButtonDemoMode";
import SecurityGate from "../../../../provider/SecurityGate";
import TableLevelClients from "./components/TableLevelClients";

import { getCurrencyValueWithRoundSign } from "../../../../utils/helper/general";
import { getManagedUsers } from "../../../../utils/helper/specialized";

import PlusOutlined from "../../../../icon/PlusOutlined";

const PageNextLevelClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const getTotalAum = () =>
    getManagedUsers(state).length
      ? getManagedUsers(state).reduce(
          (acc, it) =>
            it.valueMap?.productMap
              ? acc +
                Object.keys(it.valueMap.productMap)
                  .map(
                    key =>
                      it.valueMap.productMap[key][
                        it.valueMap.productMap[key].length - 1
                      ].investmentAmount
                  )
                  .reduce((acc, value) => acc + value, 0)
              : acc + (it.proposedAmount ?? 0),
          0
        )
      : 0;

  return (
    <SecurityGate state={state}>
      <Row justify={"space-between"}>
        <Space size={100} align="start" style={{ marginBottom: 20 }}>
          <AnalyticValue
            label={"Total Clients"}
            value={getManagedUsers(state).length}
          />
          <AnalyticValue
            label={"Total Proposed AUM"}
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Space>
        <Space size={14}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              state.setKeyValue("activeEmail", null);
              state.setKeyValue("openModalAddManagedUser", true);
            }}
            shape="round"
            size="small"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.buttonAddUserBackground,
            }}
            type="primary"
          >
            Add New Client
          </Button>
        </Space>
      </Row>
      <TableLevelClients />
    </SecurityGate>
  );
};

export default PageNextLevelClients;
