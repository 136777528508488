import styled from "styled-components";
import { Flex } from "antd";

import background from "./images/background.svg";

const StylesContainer = styled(Flex)`
  box-sizing: border-box;
  padding: 59px 55px;
  height: 100%;

  &:after {
    background: url(${background}) no-repeat right;
    background-size: contain;
    content: "";
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 390px;
    z-index: 0;
  }

  header {
    border-bottom: 1px solid #c9c8c8;
    max-width: 1009px;
    padding-bottom: 26px;
    width: 100%;
  }

  .title {
    color: #02494e;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin: 0 0 18px;
  }

  .description {
    color: #02494e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }

  .body {
    box-sizing: border-box;
    max-width: 1200px;
    padding: 77px 0 77px 72px;
  }

  .form-container {
    min-width: 380px;
    width: min-content;
    z-index: 1;

    .ant-input-number {
      width: 100%;
    }
  }

  .hero-section {
    background: #fff;
    border-radius: 12px;
    border-top: 1px solid var(--Forest-Tertiary, #749b9e);
    border-right: 1px solid var(--Forest-Tertiary, #749b9e);
    border-bottom: 5px solid var(--Forest-Tertiary, #749b9e);
    border-left: 1px solid var(--Forest-Tertiary, #749b9e);
    box-sizing: border-box;
    margin-top: 60px;
    padding: 32px;
    position: relative;
    width: 403px;
    z-index: 1;

    .icon-container {
      background: #fff;
      border-radius: 12px;
      box-sizing: border-box;
      height: 93px;
      padding: 24px;
      position: absolute;
      top: -60px;
      width: 93px;

      border-top: 1px solid var(--Forest-Tertiary, #749b9e);
      border-right: 1px solid var(--Forest-Tertiary, #749b9e);
      border-bottom: 5px solid var(--Forest-Tertiary, #749b9e);
      border-left: 1px solid var(--Forest-Tertiary, #749b9e);
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenMDMax}px) {
    padding: 42px 25px 28px;

    &:after {
      bottom: -60px;
      left: -14px;
      height: 100vw;
      rotate: 90deg;
      top: unset;
      width: calc(100% + 28px);
    }

    header {
      padding-bottom: 16px;
    }

    .logo {
      height: 38px;
      width: 123px;
    }

    .body {
      padding: 32px 0;
    }

    .form-container {
      width: 340px;
      min-width: unset;
    }

    .risk-line-image {
      display: none;
    }

    .hero-section {
      display: none;
    }
  }
`;

export default StylesContainer;
