import { PROPOSAL_SAMPLE_URL_LEVEL } from "../../../ecosystem/level/constants";

import default_preview from "../images/default_preview.png";
import oa_preview from "../images/oa_preview.png";

export const samplesData = {
  astor: {
    previewImageSrc: default_preview,
    samplesList: [
      {
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Equity_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Equity_Income.pdf",
          },
        ],
        lastUpdateDate: "02/24/2023",
        name: "//TODO",
      },
    ],
  },
  default: {
    previewImageSrc: default_preview,
    samplesList: [
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "01/04/2023",
        name: "Aggressive Growth Strategy",
      },
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "01/06/2023",
        name: "Moderate Growth Strategy",
      },
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "01/09/2023",
        name: "Conservative Growth Strategy",
      },
    ],
  },
  oneascent: {
    lastUpdateDate: "02/24/2023",
    previewImageSrc: oa_preview,
    samplesList: [
      {
        name: "OneAscent Peak Equity Core",
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Equity_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Equity_Income.pdf",
          },
        ],
      },
      {
        name: "OneAscent Peak Growth Core",
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Growth_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Growth_Income.pdf",
          },
        ],
      },
      {
        name: "OneAscent Peak Moderate Core",
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Moderate_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Moderate_Income.pdf",
          },
        ],
      },
      {
        name: "OneAscent Peak Conservative Core",
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Conservative_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Conservative_Income.pdf",
          },
        ],
      },
      {
        name: "OneAscent Peak Preservation Core",
        buttonsList: [
          {
            name: "growth",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Preservation_Growth.pdf",
          },
          {
            name: "income",
            link: "https://ontrajectory.s3.amazonaws.com/oneascent/sample-proposals/Preservation_Income.pdf",
          },
        ],
      },
    ],
  },
  rivershares: {
    previewImageSrc: default_preview,
    samplesList: [
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "06/10/2023",
        name: "RiverShares Moderate Tactical Income",
      },
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "06/10/2023",
        name: "RiverShares Global Balanced",
      },
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "06/10/2023",
        name: "RiverShares Global Balanced Opportunistic",
      },
      {
        buttonsList: [
          {
            name: "",
            link: PROPOSAL_SAMPLE_URL_LEVEL,
          },
        ],
        lastUpdateDate: "06/10/2023",
        name: "RiverShares Global Balanced Constrained",
      },
    ],
  },
};
