import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import State from "../../context";
import { Flex, theme } from "antd";
import NextAnalyticsSection from "./components/NextAnalyticsSection";
import RecentUsersSection from "./components/RecentUsersSection";
import LeadsAnalyticsSection from "./components/LeadsAnalyticsSection";

import {
  getLatestSavedProposalProps,
  getQuarterNumber,
} from "../../utils/helper/specialized";
import LevelAnalyticsSection from "./components/LevelAnalyticsSection";

const SectionAdvisorDashboardAnalytics = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [quarterAnalytics, setQuarterAnalytics] = useState({});
  const [radialBarSeries, setRadialBarSeries] = useState([]);
  const [recentProposalsList, setResentProposalsList] = useState([]);

  useEffect(() => {
    if (!state.loading && state.managedUsers?.length) {
      const totalSavedProposals = state.managedUsers?.filter(
        it => it.valueMap?.productMap
      );

      setResentProposalsList(
        totalSavedProposals
          .sort(
            (a, b) =>
              getLatestSavedProposalProps(a.valueMap.productMap).timestamp -
              getLatestSavedProposalProps(b.valueMap.productMap).timestamp
          )
          .reverse()
      );

      const currentYearProposals = totalSavedProposals.filter(it =>
        dayjs(
          getLatestSavedProposalProps(it.valueMap.productMap).timestamp
        ).isAfter(dayjs().format("YYYY"))
      );

      const currentQuarterProposals = currentYearProposals.filter(
        it =>
          getQuarterNumber(
            getLatestSavedProposalProps(it.valueMap.productMap)?.timestamp
          ) === currentQuarter
      );

      const lastQuarterProposals = currentYearProposals.filter(
        it =>
          getQuarterNumber(
            getLatestSavedProposalProps(it.valueMap.productMap)?.timestamp
          ) ===
          currentQuarter - 1
      );

      const currentQuarterConversions = currentQuarterProposals.filter(
        it => it.userType === "ConvertedProspect"
      );

      setQuarterAnalytics({
        currentQuarterProposals,
        currentQuarterConversions,
        lastQuarterProposals,
      });

      setRadialBarSeries(
        getRadialBarSeries({
          currentQuarterConversions,
          currentQuarterProposals,
        })
      );
    }
  }, [state]);

  const currentQuarter = getQuarterNumber();

  const getRadialBarSeries = ({
    currentQuarterConversions,
    currentQuarterProposals,
  }) => {
    const series = [];

    if (state.quarterly?.proposals) {
      const proposalGeneratedPercent =
        (currentQuarterProposals?.length / state.quarterly.proposals) * 100;

      series.push({
        name: "Proposals Generated",
        percent:
          proposalGeneratedPercent > 100
            ? 100
            : Math.floor(proposalGeneratedPercent),
        color: token.quarterlyGoalProposalColor,
      });
    } else {
      series.push({
        name: "Proposals Generated",
        percent: 100,
        color: token.quarterlyGoalProposalColor,
      });
    }

    if (state.quarterly?.clientConversion) {
      const clientConversionPercent = Math.floor(
        (currentQuarterConversions?.length /
          (state.quarterly.clientConversion ?? 0)) *
          100
      );

      series.push({
        name: "Client Conversions",
        percent: clientConversionPercent > 100 ? 100 : clientConversionPercent,
        color: token.quarterlyGoalClientColor,
      });
    } else {
      series.push({
        name: "Client Conversions",
        percent: 100,
        color: token.quarterlyGoalClientColor,
      });
    }

    if (state.quarterly?.newAUM) {
      const newAumPercent =
        (currentQuarterProposals?.reduce((acc, elem) => {
          return (
            acc +
            (getLatestSavedProposalProps(elem.valueMap?.productMap)
              ?.investmentAmount ?? +elem.proposedAmount)
          );
        }, 0) /
          state.quarterly.newAUM) *
        100;

      series.push({
        name: "New AUM",
        percent: newAumPercent > 100 ? 100 : Math.floor(newAumPercent),
        color: token.quarterlyGoalAumColor,
      });
    } else {
      series.push({
        name: "New AUM",
        percent: 100,
        color: token.quarterlyGoalAumColor,
      });
    }

    return series;
  };

  return (
    <Flex
      className={state.dashboardBlurred && "blurred-content"}
      vertical
      gap={20}
      style={{
        width: token.pageDashboardRightSideSectionHidden ? 822 : "100%",
      }}
    >
      {state.access?.next && !state.access?.level ? (
        <NextAnalyticsSection />
      ) : (
        <LevelAnalyticsSection
          radialBarSeries={radialBarSeries}
          quarterAnalytics={quarterAnalytics}
        />
      )}

      {state.access?.next && (
        <LeadsAnalyticsSection
          leadsList={recentProposalsList?.filter(
            it =>
              it.userType === "Affiliated" ||
              it.userType === "Assigned" ||
              it.userType === "Lead"
          )}
        />
      )}

      <RecentUsersSection
        hideClients={state.access?.next && !state.access?.level}
        recentProposalsList={recentProposalsList}
        showLeads={
          state.access?.affiliate || state.access?.level || state.access?.next
        }
      />
    </Flex>
  );
};

export default SectionAdvisorDashboardAnalytics;
