export const DEFAULT_PROPOSAL_DATA_LEVEL = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 500000,
  investmentDuration: 20,
  investmentObjective: "growth",
  organization: "level2",
  riskTolerance: "Moderate",
  withdrawalLevel: "4%",
  yearToStartWithdrawals: 1,
};

export const LIST_PDF_PAGES = [
  {
    type: "growth",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalGrowth",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
  {
    type: "income",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalIncome",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
];

export const PROPOSAL_SAMPLE_URL_LEVEL =
  "https://ontrajectory.s3.amazonaws.com/level/level_proposal_sample.pdf";
