import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Layout, Row, theme } from "antd";
import State from "../../context";
import AdminHeader from "./components/AdminHeader";
import AdvisorHeader from "./components/AdvisorHeader";
import MenuProfile from "../../molecule/MenuProfile";
import MenuSidebar from "../../molecule/MenuSidebar";
import ModalAddProspect from "../../organism/ModalAddProspect";
import ModalManagerSettings from "../../organism/ModalManagerSettings";
import ModalAdvisorArtBoard from "../../organism/ModalAdvisorArtBoard";
import SectionAskAdvice from "../../organism/SectionAskAdvice";
import ModalProposalGuideManager from "../../organism/ModalProposalGuideManager";
import PennyChatToggler from "../../organism/SectionAskAdvice/components/PennyChatToggler";

import StyledLayout from "./styles";

import {
  isOrganizationLevel,
  isPortalAdmin,
} from "../../utils/helper/specialized";
import ModalManagedUserData from "../../organism/ModalManagedUserData";

const { Content, Sider } = Layout;

const LayoutManager = ({ routes, children }) => {
  const location = useLocation();
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);

  return (
    <StyledLayout hasSider sidebarbackground={token.sidebarBackground}>
      <Sider breakpoint="md" collapsedWidth="0" width={184}>
        <Flex
          justify="space-between"
          style={{
            height: "100%",
            padding: "56px 0 64px",
            width: "100%",
          }}
          vertical
        >
          <MenuSidebar routes={routes} />
          <MenuProfile />
        </Flex>
      </Sider>

      <Layout
        style={{
          backgroundColor: location.pathname.includes(
            "advisor-portal/dashboard"
          )
            ? "#fafafa"
            : "#FFFFFF",
          minWidth: 800,
          overflow: "auto",
          width: "auto",
        }}
      >
        {isPortalAdmin() ? (
          <AdminHeader
            organization={state.organization?.name ?? state.orgName}
          />
        ) : (
          <AdvisorHeader
            organization={state.organization?.name ?? state.orgName}
          />
        )}
        <Row
          justify="center"
          style={{
            height: "100%",
          }}
        >
          <Content
            style={{
              overflow: "auto",
              padding: "28px 100px 50px 47px",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isOrganizationLevel(state.organization?.name ?? state.orgName) && (
        <PennyChatToggler
          handleClick={() => state.openModal("openModalAskAdvice")}
        />
      )}

      <ModalManagedUserData
        handleClose={() => state.setKeyValue("openModalAddManagedUser", false)}
        open={state.openModalAddManagedUser}
      />
      <ModalAddProspect
        handleClose={() => state.closeModal("addProspectModal")}
        open={state.addProspectModal}
      />
      <ModalManagerSettings
        onCancel={() => state.setKeyValue("openModalManagerSettings", false)}
        open={state.openModalManagerSettings}
      />
      <ModalAdvisorArtBoard />
      <SectionAskAdvice setState={setState} state={state} />
      <ModalProposalGuideManager
        handleClose={() => state.setKeyValue("openModalProposalGuide", false)}
        open={state.openModalProposalGuide}
      />
    </StyledLayout>
  );
};

export default LayoutManager;
