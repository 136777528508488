import dayjs from "dayjs";

import AskPenny from "../../icon/AskPenny";
import Client from "../../icon/Client";
import Dashboard from "../../icon/Dashboard";
import IconGoalsNew from "../../icon/GoalsNew";
import Lead from "../../icon/Lead";
import Lock from "../../icon/Lock";
import Marketing from "../../icon/Marketing";
import Product from "../../icon/Product";
import Proposal from "../../icon/Proposal";
import User from "../../icon/User";
import Users from "../../icon/Users";

import { showTabNewClients } from "../../ecosystem/next/helpers";

export const DEFAULT_BIRTH_YEAR = 1987;

export const DEFAULT_PRODUCT_ACCENT_COLOR = "#93191F";

export const DEFAULT_ADVISOR_FEE = 1;

export const DEFAULT_RETIREMENT_AGE = 67;

export const DEFAULT_START_WITHDRAWAL_YEAR = 1;

export const DEFAULT_WITHDRAWAL_LEVEL = "4%";

export const GOALS_RISK_TYPES = [
  { value: "aggressive", label: "Aggressive" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
];

export const HUBSPOT_FORM_ID_ASK_ADVISOR =
  "a208eb1b-8542-44d0-b620-717d34166291";

export const MAX_COMPARE_PRODUCTS = 3;

export const MC_DEFAULT_SETTINGS = {
  mcExpectedReturn: 8.88,
  mcVolatilityReturn: 18.54,
  mcExpectedInflation: 0,
  mcVolatilityInflation: 0,
};

export const MIN_BIRTH_YEAR = 1920;

export const NEW_CLIENT_DEFAULT_DATA = {
  firstName: "",
  lastName: "",
  email: "",
  doNotNotifyManagedUser: true,
  enableOnboardingQuestions: true,
  maritalStatus: "single",
  planningType: "Cashflow",
  planType: "basic",
  retirementAge: DEFAULT_RETIREMENT_AGE,
  scenarioName: "Scenario 1",
  startDate: dayjs(),
  yearBorn: 1980,
  totalSavings: 0,
  annualIncome: 0,
  savingsPerMonth: 0,
  accounts: [
    {
      key: 0,
      accountType: "529 Plan",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 5,
    },
    {
      key: 1,
      accountType: "Other Account",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 4.5,
    },
  ],
  incomes: [
    {
      key: 0,
      incomeType: "Regular Income",
      annualAmount: 0,
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      incomeType: "Social Security",
      annualAmount: 21500,
      startAge: 43,
      endAge: 90,
    },
  ],
  expenses: [
    {
      key: 0,
      expenseType: "Vehicle Payment",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      expenseType: "Property Taxes",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
  ],
};

export const PENDO_HOSTNAMES_LIST = [
  "align.oneascent.com",
  "go.ontrajectory.com",
  "www.goals.ontrajectory.com",
  "leveldemo.clevercx.com",
];

export const PENNY_FEEDBACK_FORM_URL =
  "https://share.hsforms.com/1TfzAHOV8SzSlz6j_6u1-4gdch11";

export const PENNY_TIPS_LIST = [
  {
    title: "How should I plan for retirement?",
    description: "Understand the basics of retirement planning.",
  },
  {
    title: "How much should I have in my emergency savings?",
    description: "Penny can help you figure out the right amount to save.",
  },
  {
    title: "What are the best questions to ask a financial advisor?",
    description: "Let Penny help you prepare for a conversation.",
  },
];

export const PRODUCTS_FACT_SHEETS_LIST = {
  "OneAscent Peak Equity Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Equity-Core-Fact-Sheet.pdf",
  "OneAscent Peak Growth Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Growth-Core-Fact-Sheet.pdf",
  "OneAscent Peak Moderate Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Moderate-Core-Fact-Sheet.pdf",
  "OneAscent Peak Conservative Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Conservative-Core-Fact-Sheet.pdf",
  "OneAscent Peak Preservation Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Preservation-Core-Fact-Sheet.pdf",
  "Aggressive Growth Strategy": "",
  "Moderate Growth Strategy": "",
  "Conservative Growth Strategy": "",
};

export const SIDEBAR_TABS = {
  access: {
    affiliate: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        Icon: AskPenny,
        key: "askPenny",
        path: "/next-advisor-portal/ask-penny",
        text: "AskPenny",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    level: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: Client,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    levelNext: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        hidden: !showTabNewClients(),
        Icon: Client,
        path: "/next-advisor-portal/proposals-new",
        text: "Clients New",
      },
      {
        Icon: Product,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: Marketing,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    next: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    nextProportal: [
      {
        Icon: Dashboard,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    proportal: [
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Lead,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Client,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: Lock,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
  },
  astor: {
    admin: [
      {
        path: "/astor-admin-portal/advisors",
        Icon: User,
        text: "Advisors",
      },
      {
        path: "/astor-admin-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-admin-portal/proposal",
        Icon: Proposal,
        text: "Proposal",
      },
      {
        path: "/astor-admin-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
      {
        path: "/astor-admin-portal/clients",
        Icon: Client,
        text: "Clients",
      },
    ],
    advisor: [
      {
        path: "/astor-advisor-portal/dashboard",
        Icon: User,
        text: "Dashboard",
      },
      {
        path: "/astor-advisor-portal/leads",
        Icon: Users,
        text: "Leads",
      },
      {
        path: "/astor-advisor-portal/clients",
        Icon: Client,
        text: "Clients",
      },
      {
        path: "/astor-advisor-portal/products",
        Icon: Product,
        text: "Products",
      },
      {
        path: "/astor-advisor-portal/marketing",
        Icon: Marketing,
        text: "Marketing",
      },
    ],
  },
  disabled: [
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/dashboard",
      text: "Dashboard",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/leads",
      text: "Leads",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/proposals",
      text: "Clients",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/products",
      text: "Products",
    },
    {
      disabled: true,
      Icon: Lock,
      path: "/next-advisor-portal/marketing",
      text: "Marketing",
    },
  ],
  proposal: {
    level: [
      {
        Icon: IconGoalsNew,
        path: "/proposal/dashboard",
      },
    ],
  },
};

export const TERMS_LINK = "https://qa.ontrajectory.com/terms-of-service";
